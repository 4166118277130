<div
  class="custom-select-container"
  [ngClass]="{
    disabled: disabled,
  }"
  (click)="onSelectClick()"
>
  <ng-container *ngIf="icon">
    <div
      class="icon-cont"
      [appSvgIcon]="iconPath"
      [strokeColor]="
        !selectedOption && !valueIn ? 'var(--ghost)' : 'var(--milk)'
      "
    ></div>
  </ng-container>
  <div class="custom-select-display">
    <span
      [ngStyle]="{
        color: selectedOption || valueIn ? 'var(--milk)' : 'var(--ghost)',
      }"
    >
      {{
        selectedOption
          ? selectedOption.name + " " + selectedOption.dial_code
          : valueIn
            ? valueIn.name + " " + valueIn.dial_code
            : placeholderSelector
      }}
    </span>
  </div>
  <ng-container *ngIf="icon">
    <div
      class="icon-cont"
      [appSvgIcon]="iconPathSecond"
      [strokeColor]="
        !selectedOption && !valueIn ? 'var(--ghost)' : 'var(--milk)'
      "
    ></div>
  </ng-container>
</div>

<app-countries-selector
  #countriesSelector
  (countrySelect)="countrySelected($event)"
>
</app-countries-selector>
