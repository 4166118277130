<div class="content-pages">
  <div class="text-title" w-100 *ngIf="isMobile">
    <app-tctext
      class="text"
      fz-24
      fw-400
      m-0
      TextStyle-Secondary
      feature="g-events"
      key="all.title"
      (click)="routeGeneral()"
      [ngClass]="{ routeSelected: !routeOption }"
    ></app-tctext>
    <app-tctext
      class="text"
      fz-24
      fw-400
      m-0
      TextStyle-Secondary
      feature="g-events"
      key="all.titleUp"
      (click)="routeUpcoming()"
      [ngClass]="{ routeSelected: routeOption }"
      *ngIf="isLoggedIn"
    ></app-tctext>
  </div>

  <main w-100>
    <router-outlet></router-outlet>
  </main>
</div>
