import { Component, Input, OnInit } from '@angular/core';
import { EVENTS_ROUTES } from '@/app/features/events/events-routing.module';
import { ToolbarNavigationService } from '@/app/libraries/core/navigation/services/toolbar-navigation.service';
import { dateFormat } from '@/app/libraries/core/dates/date.interface';
import { DateService } from '@/app/libraries/core/dates/services/date.service';

@Component({
  selector: 'app-tc-event-hero-card',
  templateUrl: './tc-event-hero-card.component.html',
  styleUrls: ['./tc-event-hero-card.component.scss'],
})
export class TcEventHeroCardComponent implements OnInit {
  @Input() dateTime!: any;
  @Input() eventTitle!: string;
  @Input() eventLocation!: string;
  @Input() eventInfo!: string;
  @Input() image!: string;
  @Input() id!: string;
  @Input() upcoming = false;
  initialDateValues!: dateFormat;
  finalDateValues!: dateFormat;

  constructor(
    private dateService: DateService,
    private toolbarNavigationService: ToolbarNavigationService,
  ) {}

  ngOnInit(): void {
    this.initialDateValues = this.dateService.convertToLocalDate(
      this.dateTime.initial_date,
    );
    this.finalDateValues = this.dateService.convertToLocalDate(
      this.dateTime.final_date,
    );
  }

  eventDetail() {
    this.toolbarNavigationService.navigateToNewTab([
      `${EVENTS_ROUTES.EVENTS}/${this.id}`,
    ]);
  }
}
