import { userData } from '@/app/features/authentication/constants';
import { Component, EventEmitter, Output } from '@angular/core';
import { TypesButton } from '../../atoms/button/constants';
import { AuthService } from '@/app/libraries/authentication/services/auth-service.service';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';
import { NavigationService } from '@/app/libraries/main-layout/services/navigation.service';
import { LinkRedirects } from '../../molecules/footer/constants';
import { LangScreenName } from '../../molecules/language-dropdown/constants';
import { ToolbarNavigationService } from '@/app/libraries/core/navigation/services/toolbar-navigation.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent {
  userData: userData | null = null;
  isMobile = false;
  TypesButton = TypesButton;
  LinkRedirects = LinkRedirects;
  @Output() eventLogOut = new EventEmitter<boolean>();

  constructor(
    private authService: AuthService,
    public navigationService: NavigationService,
    private screenSizeService: ScreenSizeService,
    private toolbarNavigationService: ToolbarNavigationService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.authService.dataUser$.subscribe((data) => {
      this.userData = data;
    });
  }

  langModal() {
    this.toolbarNavigationService.navigateForwardModal(LangScreenName, {});
  }

  logOut() {
    this.authService.logout();
    this.eventLogOut.emit(true);
  }
}
