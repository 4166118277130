import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { FeatureFlag, FeatureFlagsResponse } from './types/feature-flags';
import { map, Observable, tap } from 'rxjs';
import { AppConstants } from '../constants/constants.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  http = inject(HttpClient);
  features = signal<FeatureFlag[]>([]);
  urlAPI = AppConstants.API_URL;

  getFeatureFlags(): Observable<FeatureFlag[]> {
    return this.http
      .get<FeatureFlagsResponse>(`${this.urlAPI}/featureflags/`)
      .pipe(
        map((response) =>
          response.map((flag) => ({
            internalKey: flag.internal_key,
            enabled: flag.enabled,
            description: flag.description,
          })),
        ),

        tap((flags) => this.features.set(flags)),
      );
  }

  getFeature(feature: string): boolean {
    return this.features().some(
      (flag) => flag.internalKey === feature && flag.enabled,
    );
  }
}
