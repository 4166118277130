<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '100%' }"
  [draggable]="false"
  [resizable]="false"
  [showHeader]="false"
  [styleClass]="
    modalClosing && isMobile
      ? 'countries-selector-modal closing'
      : 'countries-selector-modal'
  "
>
  <div id="modal-content-countries" class="modal-content" pb-24>
    <div class="close">
      <app-tc-icon
        [iconId]="'system_close_modal'"
        (click)="closeModal()"
      ></app-tc-icon>
      <app-tctext
        TextStyle
        [feature]="'g-auth'"
        [key]="'phone.country'"
      ></app-tctext>
    </div>
    <app-country-selector
      [data]="params"
      (countrySelected)="countrySelected($event)"
    ></app-country-selector>
  </div>
</p-dialog>
