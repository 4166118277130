import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CarouselModule } from 'primeng/carousel';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { LibrariesModule } from '../libraries/Libraries.module';

import { TCButtonComponent } from './atoms/button/tcbutton.component';
import { TcCtaComponent } from './atoms/cta/tc-cta.component';
import { TcIconComponent } from './atoms/icon/tc-icon.component';
import { TCInputComponent } from './atoms/input/tcinput.component';
import { OtpInputComponent } from './atoms/otp-input/otp-input.component';
import { TcTitleComponent } from './atoms/title/tc-title.component';
import { TCTextComponent } from './atoms/text/text/tctext.component';
import { InfoCardComponent } from './molecules/info-card/info-card.component';
import { SelectComponent } from './atoms/select/select.component';

import { FooterComponent } from './molecules/footer/footer.component';
import { HeaderComponent } from './molecules/header/header.component';

import { TcHeaderComponent } from './molecules/header-section/tc-header.component';
import { TcIdentityComponent } from './molecules/identity/tc-identity.component';
import { LanguageDropdownComponent } from './molecules/language-dropdown/language-dropdown.component';
import { UserProfileComponent } from './sections/user-profile/user-profile.component';
import { CountrySelectorComponent } from './molecules/country-selector/country-selector.component';
import { ToolbarComponent } from '../topcars-ui/toolbar/toolbar.component';
import { LoadingSkeletonComponent } from './loading-skeleton/loading-skeleton.component';

import { SelectLocationComponent } from './modals/select-location/select-location.component';
import { ErrorModalComponent } from './modals/error-modal/error-modal.component';
import { EditPictureComponent } from './modals/edit-picture/edit-picture.component';
import { PermissionsComponent } from './modals/permissions/permissions.component';
import { ModalExternalUrlComponent } from './modals/modal-external-url/modal-external-url.component';
import { UserConfigComponent } from './modals/user-config/user-config.component';
import { ModalService } from '../libraries/main-layout/services/modal.service';
import { UserLanguageComponent } from './modals/user-language/user-language.component';
import { CountriesSelectorComponent } from './modals/countries-selector/countries-selector.component';
import { AuthFlowComponent } from '../features/user-profile/ui/flows/auth-flow/auth-flow.component';
import { TranslateModule } from '@ngx-translate/core';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    TCButtonComponent,
    TcCtaComponent,
    TcIconComponent,
    TCInputComponent,
    OtpInputComponent,
    TcTitleComponent,
    TCTextComponent,
    InfoCardComponent,
    SelectComponent,

    HeaderComponent,
    FooterComponent,
    ToolbarComponent,
    TcHeaderComponent,
    TcIdentityComponent,
    LanguageDropdownComponent,
    UserProfileComponent,
    CountrySelectorComponent,

    LoadingSkeletonComponent,
    AuthFlowComponent,

    SelectLocationComponent,
    ErrorModalComponent,
    EditPictureComponent,
    PermissionsComponent,
    ModalExternalUrlComponent,
    UserConfigComponent,
    UserLanguageComponent,
    CountriesSelectorComponent,
  ],
  imports: [
    CommonModule,
    LibrariesModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(maskConfig),
    CarouselModule,
    DialogModule,
    DynamicDialogModule,
    TranslateModule,
  ],
  exports: [
    TCButtonComponent,
    TcCtaComponent,
    TcIconComponent,
    TCInputComponent,
    OtpInputComponent,
    TcTitleComponent,
    TCTextComponent,
    InfoCardComponent,
    SelectComponent,

    HeaderComponent,
    FooterComponent,
    ToolbarComponent,
    TcHeaderComponent,
    TcIdentityComponent,
    LanguageDropdownComponent,
    UserProfileComponent,
    CountrySelectorComponent,

    LoadingSkeletonComponent,
    AuthFlowComponent,

    SelectLocationComponent,
    ErrorModalComponent,
    EditPictureComponent,
    PermissionsComponent,
    ModalExternalUrlComponent,
    UserConfigComponent,
    UserLanguageComponent,
    CountriesSelectorComponent,
  ],
})
export class DesignSystemModule {
  constructor(private modalService: ModalService) {
    this.modalService.registerModal('langdropdown', () =>
      import('./molecules/language-dropdown/language-dropdown.component').then(
        (m) => m.LanguageDropdownComponent,
      ),
    );
    this.modalService.registerModal('userprofile', () =>
      import('./sections/user-profile/user-profile.component').then(
        (m) => m.UserProfileComponent,
      ),
    );
    this.modalService.registerModal('countrySelector', () =>
      import('./molecules/country-selector/country-selector.component').then(
        (m) => m.CountrySelectorComponent,
      ),
    );
  }
}
