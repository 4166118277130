import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConstants } from '@/app/libraries/core/constants/constants.service';

@Injectable({
  providedIn: 'root',
})
export class ApiHttpClient {
  private apiUrl = AppConstants.API_URL;

  constructor(private http: HttpClient) {}

  private buildUrl(url: string): string {
    return `${this.apiUrl}${url}`;
  }

  get<T>(
    url: string,
    params?: object,
    options?: object,
  ): Observable<HttpResponse<T>> {
    const apiUrl = this.buildUrl(url);
    const httpOptions = {
      params: new HttpParams({ fromObject: params as any }),
      observe: 'response' as const,
      ...options,
    };
    return this.http
      .get<T>(apiUrl, httpOptions)
      .pipe(catchError(this.handleError));
  }

  post<T>(
    url: string,
    body: object,
    options?: object,
  ): Observable<HttpResponse<T>> {
    const apiUrl = this.buildUrl(url);
    return this.http
      .post<T>(apiUrl, body, { ...options, observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  put<T>(
    url: string,
    body: object,
    options?: object,
  ): Observable<HttpResponse<T>> {
    const apiUrl = this.buildUrl(url);
    return this.http
      .put<T>(apiUrl, body, { ...options, observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  patch<T>(
    url: string,
    body: object,
    options?: object,
  ): Observable<HttpResponse<T>> {
    const apiUrl = this.buildUrl(url);
    return this.http
      .patch<T>(apiUrl, body, { ...options, observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    return throwError(() => error);
  }
}
