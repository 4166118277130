<div class="card-container">
  <div class="image-section">
    <img class="event-image" [src]="image" alt="Exotic Car Show" />
  </div>
  <div class="details-section">
    <div class="date-time" pb-4 fz-12 fw-700 [ngClass]="{ concrete: upcoming }">
      <span>{{ dateTime?.caption }}</span>
    </div>
    <h2 class="event-title" pb-4 fz-14 fw-700 m-0>{{ eventTitle }}</h2>
    <div class="event-location" fz-12>
      <p m-0>{{ eventLocation }}</p>
    </div>
    <div class="event-info" m-0 py-4 fz-10>
      <p m-0>{{ eventInfo }}</p>
    </div>
  </div>

  <div *ngIf="upcoming" class="date">
    <h4 fz-22 m-0>{{ initialDateValues.day }}</h4>
    <h4 fz-18 m-0>{{ initialDateValues.month.slice(0, 3) }}</h4>
  </div>
  <app-tc-icon
    *ngIf="upcoming"
    class="icon"
    [iconId]="'system_redirect_detail'"
    (click)="eventDetail()"
  ></app-tc-icon>
</div>
