import { ScreenConfigService } from '@/app/libraries/main-layout/services/screen-config.service';
import { ChangeDetectorRef, Component, OnInit, Optional } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import { ValidatorService } from 'src/app/libraries/core/validator-interface/services/validator.service';
import { TextService } from '@/app/design-system/atoms/text/services/text.service';
import { ScreenSizeService } from '../../../libraries/main-layout/services/screen-size.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import {
  ScreenPresentation,
  ScreenTitleConfig,
  toolbarOptions,
} from '@/app/libraries/main-layout/constants/screen-configuration';
import { HttpMethodsService } from '@/app/libraries/core/http-methods/services/http-methods-service.service';
import { ErrorModalService } from '@/app/libraries/core/modals/services/error-modal.service';
import { PATH_CLAIM_USERNAME } from '@/app/features/user-profile/constants';
import { ToolbarNavigationService } from '@/app/libraries/core/navigation/services/toolbar-navigation.service';
import { TypesTitles } from '@/app/design-system/atoms/title/constants';
import { TypesButton } from '@/app/design-system/atoms/button/constants';
import {
  UsernameScreenName,
  UsernameScreenTitleConfig,
} from './username.config';
import { ProfileScreenName } from '../profile/profile.config';
import { AuthService } from '@/app/libraries/authentication/services/auth-service.service';
import { TRANSLATIONS } from '@/app/libraries/core/translations/constants/translations';

@Component({
  selector: 'app-username',
  templateUrl: './username.component.html',
  styleUrls: ['./username.component.scss'],
})
export class UsernameComponent implements OnInit {
  placeHolder = '';
  form!: FormGroup;
  isUsernameInvalid = true;
  textUser = true;
  usernameValue = '';
  currentRoute = '';
  flow = '';
  toolbarActions!: toolbarOptions;
  isMobile = false;
  isLoading = true;
  params: any;
  TypesTitles = TypesTitles;
  TypesButton = TypesButton;
  data!: ScreenTitleConfig;
  translate = TRANSLATIONS.userProfile;

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private textService: TextService,
    private authService: AuthService,
    private validatorService: ValidatorService,
    private screenSizeService: ScreenSizeService,
    private errorModalService: ErrorModalService,
    private httpMethodsService: HttpMethodsService,
    @Optional() private config: DynamicDialogConfig,
    private screenConfigService: ScreenConfigService,
    private toolbarNavigationService: ToolbarNavigationService,
  ) {
    this.setUsernameScreenConfig();
    setTimeout(() => {
      this.screenConfigService.config$.subscribe((config) => {
        if (config) {
          this.data = { ...config?.dataText };
          this.toolbarActions = { ...config.useToolbar };
          if (!this.isMobile && this.flow === '1') {
            this.toolbarActions.close = true;
            this.toolbarActions.pop = false;
          }
          this.cdr.detectChanges();
        }
      });
    }, 0);
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });

    this.form = this.fb.group({
      inputUsername: [null, [this.getValidator('username')]],
    });
    this.inputUsername?.statusChanges.subscribe((status) => {
      this.isUsernameInvalid = status === 'INVALID';
      this.textUser =
        this.isUsernameInvalid && this.inputUsername?.value.length > 0
          ? false
          : true;
    });
    this.inputUsername?.valueChanges.subscribe((value) => {
      const updatedValue = value.replace(/@/g, '');
      if (value !== updatedValue) {
        this.usernameValue = updatedValue;
      }
    });
  }

  get inputUsername(): AbstractControl | null {
    return this.form.get('inputUsername');
  }

  ngOnInit(): void {
    this.params = this.config?.data?.params;
    this.flow = this.params['flow'] ? window.atob(this.params['flow']) : '';
    if (this.params['username']) {
      this.inputUsername?.setValue(window.atob(this.params['username']));
      this.inputUsername?.markAsTouched();
      this.inputUsername?.updateValueAndValidity();
    }
    setTimeout(() => {
      this.getPlaceholder();
      this.isLoading = false;
    }, 500);
  }

  async getPlaceholder() {
    this.placeHolder = await this.textService.getPlaceholder(
      this.data.feature ?? '',
      this.data.placeholder?.key ?? '',
    );
  }

  private getValidator(validatorKey: string): ValidatorFn {
    const validator = this.validatorService.getValidator(validatorKey);
    return (control: AbstractControl) => {
      const isValid = validator?.validate(control.value);
      return isValid ? null : { invalid: true };
    };
  }

  onSubmit() {
    if (this.form.valid) {
      const params = {
        username: this.inputUsername?.value.split('@')[1],
      };
      const queryParams = {
        flow: window.btoa(this.flow),
        username: window.btoa(this.inputUsername?.value),
      };
      this.httpServicesPost(params, queryParams);
    }
  }
  httpServicesPost(params: object, queryParams: object) {
    this.isLoading = true;
    this.httpMethodsService.postData(PATH_CLAIM_USERNAME, params).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.authService.updateDataUser({
            username: this.inputUsername?.value.split('@')[1],
          });
          this.toolbarNavigationService.navigateForwardModal(
            ProfileScreenName,
            queryParams,
          );
          this.isLoading = false;
        }
      },
      error: async (error) => {
        await this.errorModalService.showError(
          error.status === 409
            ? { feature: this.data.feature, key: this.data.userConflict }
            : { feature: this.data.feature, key: this.data.responseError },
        );
        this.isLoading = false;
      },
    });
  }

  setUsernameScreenConfig() {
    this.screenConfigService.setScreenConfiguration({
      name: UsernameScreenName,
      dataText: UsernameScreenTitleConfig,
      useToolbar: {
        state: true,
        pop: true,
      },
      useHeader: true,
      useFooter: true,
      presentation: ScreenPresentation.Modal,
    });
  }
}
