import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { forkJoin, map, Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { TRANSLATION_PLUGIN, TranslationConfig } from './translation-config';

export const mergeObjectsRecursively = function (
  objects: Record<string, unknown>[],
): Record<string, unknown> {
  const mergedObject: Record<string, unknown> = {};

  for (const obj of objects) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          const existing = mergedObject[key] as Record<string, unknown>;
          const current = obj[key] as Record<string, unknown>;
          mergedObject[key] = mergeObjectsRecursively([
            existing || {},
            current,
          ]);
        } else {
          mergedObject[key] = obj[key];
        }
      }
    }
  }

  return mergedObject;
};

@Injectable({
  providedIn: 'root',
})
export class ModuleTranslateLoaderService implements TranslateLoader {
  resources: TranslationConfig[] = [];

  private readonly defaultTranslateConfig: Omit<TranslationConfig, 'module'> = {
    prefix: 'assets/strings/',
    suffix: '.json',
  };

  constructor(
    private http: HttpClient,
    @Inject(TRANSLATION_PLUGIN) resources: TranslationConfig[] = [],
  ) {
    this.resources = resources;
  }

  getTranslation(lang: string): Observable<Record<string, unknown>> {
    const resources: TranslationConfig[] = this.resources.flat();

    return forkJoin(
      resources.map((config: TranslationConfig) => {
        const prefix = config.prefix || this.defaultTranslateConfig.prefix;
        const suffix = config.suffix || this.defaultTranslateConfig.suffix;

        return this.http
          .get<
            Record<string, unknown>
          >(`${prefix}${config.module}/${lang}${suffix}`)
          .pipe(
            map((response) => ({
              module: config.module,
              translations: response,
            })),
          );
      }),
    ).pipe(
      map((responses) =>
        responses.reduce(
          (acc, { module, translations }) => ({
            ...acc,
            [module]: translations,
          }),
          {} as Record<string, unknown>,
        ),
      ),
    );
  }
}
