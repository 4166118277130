import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IconRegistryService {
  private icons: Map<string, SafeHtml> = new Map();

  constructor(
    private sanitizer: DomSanitizer,
    private http: HttpClient,
  ) {}

  /**
   * Registers multiple icons in a single call.
   * @param icons Object with icon names and contents
   */
  preloadIcons(icons: { name: string; content: string }[]): Observable<void[]> {
    const requests = icons.map(({ name, content }) =>
      this.registerIcon(name, content),
    );

    return forkJoin(requests).pipe(tap(() => console.log('Icons loaded')));
  }

  /**
   * Registers an icon. Detects if it's a direct SVG or a URL.
   * @param name Icon name
   * @param content SVG or icon URL
   */
  registerIcon(name: string, content: string): Observable<void> {
    if (!content) {
      return of();
    }

    if (this.isSvg(content)) {
      this.icons.set(name, this.sanitizer.bypassSecurityTrustHtml(content));
      return of();
    } else {
      return this.http.get(content, { responseType: 'text' }).pipe(
        map((svg: string) => {
          this.icons.set(name, this.sanitizer.bypassSecurityTrustHtml(svg));
        }),
        catchError((error) => {
          console.error(`Error loading icon from "${content}":`, error);
          return of();
        }),
      );
    }
  }

  /**
   * Returns the SVG of a registered icon.
   * @param name Icon name
   * @returns SafeHtml or undefined if it doesn't exist
   */
  getIcon(name: string): SafeHtml | undefined {
    return this.icons.get(name);
  }

  /**
   * Verifies if the content is a direct SVG.
   * @param content Content to analyze
   * @returns true if it's an SVG
   */
  private isSvg(content: string): boolean {
    return content?.trim().startsWith('<svg');
  }
}
