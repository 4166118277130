<div style="width: calc(100% - 3rem); display: flex; flex-direction: column">
  <app-loading-skeleton
    [type]="'label'"
    [count]="1"
    style="width: 35%"
  ></app-loading-skeleton>
  <app-loading-skeleton
    w-100
    pb-28
    [type]="'circle'"
    [count]="3"
  ></app-loading-skeleton>

  <app-loading-skeleton
    [type]="'label'"
    [count]="1"
    style="width: 35%"
  ></app-loading-skeleton>
  <div [ngClass]="{ 'grid-desktop': !isMobile }">
    <app-loading-skeleton
      w-100
      pb-28
      [type]="'card'"
      [count]="isMobile ? MOBILE_SKELETON_COUNT : DESKTOP_SKELETON_COUNT"
      style="padding: 0 0 var(--size-24) 0"
    ></app-loading-skeleton>
    <app-loading-skeleton
      *ngIf="!isMobile"
      w-100
      pb-28
      [type]="'card'"
      [count]="DESKTOP_SKELETON_COUNT"
      style="padding: 0 0 var(--size-24) 0"
    ></app-loading-skeleton>
    <app-loading-skeleton
      *ngIf="!isMobile"
      w-100
      pb-28
      [type]="'card'"
      [count]="DESKTOP_SKELETON_COUNT"
      style="padding: 0 0 var(--size-24) 0"
    ></app-loading-skeleton>
  </div>
</div>
