import { Component } from '@angular/core';
import {
  DETAIL_DESKTOP_SKELETON_COUNT,
  DETAIL_MOBILE_SKELETON_COUNT,
} from '../general-events-skeleton/constants';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';

@Component({
  selector: 'app-city-detail-skeleton',
  templateUrl: './city-detail-skeleton.component.html',
  styleUrls: ['./city-detail-skeleton.component.scss'],
})
export class CityDetailSkeletonComponent {
  isMobile = false;
  MOBILE_SKELETON_COUNT = DETAIL_MOBILE_SKELETON_COUNT;
  DESKTOP_SKELETON_COUNT = DETAIL_DESKTOP_SKELETON_COUNT;
  constructor(private screenSizeService: ScreenSizeService) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }
}
