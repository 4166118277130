<!-- <app-toolbar
  w-100
  pt-12
  *ngIf="isMobile"
  [toolbarConfig]="{
    state: true,
    close: true,
  }"
></app-toolbar> -->
<div class="drop-user">
  <app-tctext
    *ngIf="isMobile"
    text-center
    tcMilk
    fz-16
    fw-700
    pb-28
    feature="main-layout"
    key="header.settings"
    class="title"
  ></app-tctext>

  <div style="padding-bottom: 0.75rem" *ngIf="userData?.profile_picture">
    <div class="user-image" pb-12>
      <app-tc-icon
        class="icon-profile"
        [iconId]="userData?.profile_picture ?? 'system_avatar_default'"
      ></app-tc-icon>
    </div>
    <h3 m-0 pb-4 tcMilk fz-16 fw-700 *ngIf="userData?.username">
      {{ userData?.username }}
    </h3>
    <p m-0 pb-16 tcGhost fz-12 fw-500 *ngIf="userData?.full_name">
      {{ userData?.full_name }}
    </p>
  </div>

  <div w-100 d-flex flex-column pb-28 *ngIf="isMobile">
    <app-tctext
      tcMilk
      fz-12
      fw-700
      pb-12
      feature="main-layout"
      key="header.general"
      style="text-transform: uppercase"
    ></app-tctext>
    <button (click)="langModal()">
      <app-tc-icon
        [iconId]="'system_globe_translate'"
        style="padding-right: var(--size-16)"
      ></app-tc-icon>
      <app-tctext
        fz-14
        feature="main-layout"
        key="header.language"
      ></app-tctext>
      <app-tc-icon [iconId]="'system_chevron_right'"></app-tc-icon>
    </button>
  </div>

  <div
    w-100
    d-flex
    flex-column
    [ngStyle]="{
      'padding-bottom': isMobile ? 'var(--size-28)' : 'var(--size-16)',
    }"
  >
    <app-tctext
      *ngIf="isMobile"
      tcMilk
      fz-12
      fw-700
      pb-12
      feature="main-layout"
      key="header.legal"
      style="text-transform: uppercase"
    ></app-tctext>
    <button
      mb-8
      (click)="navigationService.navigateToUrl(LinkRedirects.TermsConditions)"
    >
      <app-tc-icon
        *ngIf="isMobile"
        [iconId]="'system_terms'"
        style="padding-right: var(--size-16)"
      ></app-tc-icon>
      <app-tctext
        fz-14
        feature="main-layout"
        key="header.termsConditions"
      ></app-tctext>
      <app-tc-icon
        *ngIf="isMobile"
        [iconId]="'system_chevron_right'"
      ></app-tc-icon>
    </button>
    <button
      (click)="navigationService.navigateToUrl(LinkRedirects.PrivacyPolicy)"
    >
      <app-tc-icon
        *ngIf="isMobile"
        [iconId]="'system_terms'"
        style="padding-right: var(--size-16)"
      ></app-tc-icon>
      <app-tctext
        fz-14
        feature="main-layout"
        key="header.privacyPolicy"
      ></app-tctext>
      <app-tc-icon
        *ngIf="isMobile"
        [iconId]="'system_chevron_right'"
      ></app-tc-icon>
    </button>
  </div>

  <ng-container>
    <button *ngIf="!isMobile" (click)="logOut()">
      <app-tctext
        tcError
        fz-14
        fw-500
        feature="main-layout"
        key="header.logout"
      ></app-tctext>
    </button>
    <app-tcbutton
      *ngIf="isMobile"
      [type]="TypesButton.tertiary"
      (click)="logOut()"
    >
      <app-tctext
        fz-14
        fw-500
        feature="main-layout"
        key="header.logout"
      ></app-tctext>
    </app-tcbutton>
  </ng-container>
</div>
