import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LibrariesModule } from '@/app/libraries/Libraries.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login.component';
import { DesignSystemModule } from '@/app/design-system/DesignSystem.module';
import { ModalService } from '@/app/libraries/main-layout/services/modal.service';
import { AuthViewComponent } from '../ui/flows/auth-view/auth-view.component';
import { IconsModule } from '../../../topcars-ui/icons/icons.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [LoginComponent, AuthViewComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    LibrariesModule,
    DesignSystemModule,
    ReactiveFormsModule,
    IconsModule,
    TranslateModule,
  ],
})
export class LoginModule {
  constructor(private modalService: ModalService) {
    this.modalService.registerModal('signin', () =>
      import('./login.component').then((m) => m.LoginComponent),
    );
  }
}
