import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './features/layout/main-layout/main-layout.component';
import { AuthGuard } from './libraries/authentication/guards/auth.guard';

export const APP_ROUTES = {
  AUTH: 'auth',
  USER: 'user',
  EVENTS: 'events',
};

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('./features/authentication/authentication.module').then(
            (m) => m.AuthenticationModule,
          ),
      },
      {
        path: 'user',
        loadChildren: () =>
          import('./features/user-profile/user-profile.module').then(
            (m) => m.UserProfileModule,
          ),
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
      },
      {
        path: 'events',
        loadChildren: () =>
          import('./features/events/events.module').then((m) => m.EventsModule),
      },
      { path: '', redirectTo: 'events', pathMatch: 'full' },
    ],
  },
  { path: '**', redirectTo: 'events', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
