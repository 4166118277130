<div class="event-card">
  <div class="event-image">
    <img [src]="imageUrl" alt="Event image" />
  </div>
  <div class="event-details">
    <p
      class="event-title"
      fw-500
      m-0
      [ngStyle]="{
        'font-size': isMobile ? '0.75rem' : '0.875rem',
        color: isMobile ? 'var(--concrete)' : 'var(--milk)',
        'font-family': isMobile ? 'Red-Hat-Light' : 'Red-Hat-Regular',
      }"
    >
      {{ eventOrganizer }}
    </p>
    <h3 class="event-title" fz-16 fw-700 m-0>{{ eventTitle }}</h3>
    <div d-flex style="justify-content: flex-start; align-items: center">
      <app-tc-icon
        *ngIf="!isMobile"
        [iconId]="'system_pin_events'"
        class="icon"
      ></app-tc-icon>
      <p class="event-location" TextStyle fz-14 fw-500 m-0>
        {{ eventLocation }}
      </p>
    </div>
    <div d-flex style="justify-content: flex-start; align-items: center">
      <app-tc-icon
        *ngIf="!isMobile"
        [iconId]="'system_calendar_event'"
        class="icon"
      ></app-tc-icon>
      <p
        class="event-participants"
        fz-14
        m-0
        [ngStyle]="{ 'font-weight': isMobile ? '700' : '500' }"
      >
        {{
          initialDateValues.month +
            " " +
            initialDateValues.day +
            " • " +
            initialDateValues.localTime
        }}
      </p>
    </div>
  </div>
</div>
