<div>
  <ng-container *ngIf="isLoading">
    <app-city-detail-skeleton w-100></app-city-detail-skeleton>
  </ng-container>

  <div class="city-container" *ngIf="!isLoading">
    <app-toolbar
      w-100
      [toolbarConfig]="{
        state: true,
        back: true,
        actions: false,
      }"
      style="position: absolute; top: 0; z-index: 3"
    ></app-toolbar>
    <div *ngFor="let section of sections">
      <ng-container [appDynamicSection]="section"></ng-container>
    </div>
  </div>
</div>
