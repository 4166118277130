import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserProfileRoutingModule } from './user-profile-routing.module';
import { UserProfileComponent } from './user-profile.component';
import { LibrariesModule } from 'src/app/libraries/Libraries.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AddEmailModule } from './add-email/add-email.module';
import { UsernameModule } from './username/username.module';
import { ProfileModule } from './profile/profile.module';
import { DesignSystemModule } from '@/app/design-system/DesignSystem.module';
import { TRANSLATION_PLUGIN } from '@/app/libraries/core/translations/translation-config';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [UserProfileComponent],
  imports: [
    CommonModule,
    UserProfileRoutingModule,
    LibrariesModule,
    DesignSystemModule,
    ReactiveFormsModule,
    AddEmailModule,
    UsernameModule,
    ProfileModule,
  ],
  providers: [
    {
      provide: TRANSLATION_PLUGIN,
      useValue: [
        {
          module: 'user-profile',
        },
      ],
      multi: true,
    },
  ],
})
export class UserProfileModule {}
