import { ActivatedRoute } from '@angular/router';

export interface INavigationService {
  back(): void;
  clearHistory(): void;
  getCurrentRoute(): void;
  navigateForward(
    route: string[],
    queryParams: any,
    relativeTo: ActivatedRoute | null,
  ): void;
  navigateForwardModal(path: string, queryParams: any): void;
}

export const TOOLBAR_HISTORY = 'toolbarHistory';
export const EN = 'en';
export const ES = 'es';
export const US = 'us';
export const CO = 'co';
