import { APP_INITIALIZER, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { FeatureFlagsService } from './feature-flags.service';
import { FeatureFlag } from './types/feature-flags';

function initializeFeatureFlags(): () => Observable<FeatureFlag[]> {
  const featureFlagsService = inject(FeatureFlagsService);
  console.log('Initializing feature flags');
  return () => featureFlagsService.getFeatureFlags();
}

export const featureFlagsProvider = () => ({
  provide: APP_INITIALIZER,
  useFactory: initializeFeatureFlags,
  deps: [],
  multi: true,
});
