import { Component, Input, OnInit } from '@angular/core';
import { eventTime } from '@/app/libraries/events/sdui/sdui-section.interface';
import { DateService } from '@/app/libraries/core/dates/services/date.service';
import { dateFormat } from '@/app/libraries/core/dates/date.interface';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';

@Component({
  selector: 'app-tc-event-card',
  templateUrl: './tc-event-card.component.html',
  styleUrls: ['./tc-event-card.component.scss'],
})
export class TcEventCardComponent implements OnInit {
  @Input() imageUrl!: string;
  @Input() eventOrganizer!: string;
  @Input() eventTitle!: string;
  @Input() eventLocation!: string;
  @Input() eventTime!: eventTime;
  initialDateValues!: dateFormat;
  finalDateValues!: dateFormat;
  isMobile = false;

  constructor(
    private dateService: DateService,
    private screenSizeService: ScreenSizeService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  ngOnInit(): void {
    this.initialDateValues = this.dateService.convertToLocalDate(
      this.eventTime.initial_date,
    );
    this.finalDateValues = this.dateService.convertToLocalDate(
      this.eventTime.final_date,
    );
  }
}
