<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '100%' }"
  [draggable]="false"
  [resizable]="false"
  [showHeader]="false"
  [styleClass]="
    modalClosing ? 'user-config-modal closing' : 'user-config-modal'
  "
>
  <div id="modal-content" class="modal-content" pb-24>
    <div class="close">
      <app-tc-icon
        [iconId]="'system_close_modal'"
        (click)="closeModal()"
      ></app-tc-icon>
    </div>
    <app-user-profile (eventLogOut)="logOutEvent($event)"></app-user-profile>
  </div>
</p-dialog>
