import { ValidatorPlugin } from '../../core/validator-interface/validator-plugin.interface';

export class BirthdateValidatorPlugin implements ValidatorPlugin {
  validate(value: string): boolean {
    // Format: YYYY-MM-DD
    const datePattern = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

    // Validate length
    const length = value?.length;

    if (length === 10) {
      if (!datePattern.test(value)) {
        return false;
      }

      const [year, month, day] = value.split('-').map(Number);
      const date = new Date(year, month - 1, day);

      // Verify that the date is valid
      if (
        date.getDate() !== day ||
        date.getMonth() !== month - 1 ||
        date.getFullYear() !== year
      ) {
        return false;
      }

      // Verify that the date is not in the future
      const today = new Date();
      if (date > today) {
        return false;
      }

      return true;
    }

    // Progressive validation based on the length of the entered value
    if (length > 0 && length <= 4) {
      const year = Number(value);
      const currentYear = new Date().getFullYear();
      if (year < 1900 || year > currentYear) {
        return false;
      }
    } else if (length > 5 && length <= 7) {
      const month = Number(value.slice(5, 7));
      if (month < 1 || month > 12) {
        return false;
      }
    } else if (length > 7 && length <= 10) {
      const day = Number(value.slice(8));
      if (day < 1 || day > 31) {
        return false;
      }
    }

    return true;
  }
}
