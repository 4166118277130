import { ToolbarNavigationService } from '@/app/libraries/core/navigation/services/toolbar-navigation.service';
import { EVENTS_ROUTES } from '@/app/features/events/events-routing.module';
import { Component } from '@angular/core';

@Component({
  selector: 'app-cities-section',
  templateUrl: './cities-section.component.html',
  styleUrls: ['./cities-section.component.scss'],
})
export class CitiesSectionComponent {
  data: any;

  constructor(public toolbarNavigationService: ToolbarNavigationService) {}

  cityDetail(id: string) {
    this.toolbarNavigationService.navigateForward(
      [`${EVENTS_ROUTES.EVENTS}${EVENTS_ROUTES.CITIES_DETAIL}${id}`],
      {},
    );
  }
  onCtaClick() {
    console.log('CTA clicked from parent!');
  }
}
