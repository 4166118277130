import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventsAllComponent } from './events-all.component';
import { GeneralEventsComponent } from './pages/general-events/general-events.component';
import { EventsUpcomingComponent } from './pages/events-upcoming/events-upcoming.component';

export const ALL_EVENTS_ROUTES = {
  ALL_EVENTS: 'events',
  GENERAL_EVENTS: 'events',
  EVENTS_UPCOMING: 'events/upcoming',
};

const routes: Routes = [
  {
    path: '',
    component: EventsAllComponent,
    children: [
      {
        path: '',
        component: GeneralEventsComponent,
      },
      {
        path: 'upcoming',
        component: EventsUpcomingComponent,
      },
      {
        path: '',
        redirectTo: '',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EventsAllRoutingModule {}
