<div class="toolbar">
  <app-tc-icon
    *ngIf="toolbarConfig.pop || toolbarConfig.back"
    [iconId]="'system_arrowBack'"
    (click)="closeOrGoBack()"
  ></app-tc-icon>
  <app-tc-icon
    *ngIf="toolbarConfig.close || toolbarConfig.closeAll"
    [iconId]="'system_close_modal'"
    (click)="closeOrGoBack()"
  ></app-tc-icon>

  <h3
    tcMilk
    fw-500
    fz-16
    class="toolbar-title"
    *ngIf="toolbarConfig.title?.active"
  >
    <app-tctext
      [feature]="toolbarConfig.title?.feature"
      [key]="toolbarConfig.title?.key"
    ></app-tctext>
  </h3>

  <div class="toolbar-username" *ngIf="toolbarConfig.username">
    {{ "mind1997" }}
    <app-tc-icon [iconId]="'system_verified'"></app-tc-icon>
  </div>

  <div class="toolbar-options">
    <app-tc-icon
      *ngIf="toolbarConfig.actions"
      [iconId]="'system_share_light'"
    ></app-tc-icon>
    <!-- <app-tc-icon [iconId]="'system_settings'"></app-tc-icon> -->
  </div>
</div>
