import { APP_INITIALIZER, Inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutComponent } from './features/layout/main-layout/main-layout.component';
import { LibrariesModule } from './libraries/Libraries.module';
import { TextService } from './design-system/atoms/text/services/text.service';
import { AuthInterceptor } from './libraries/authentication/interceptors/auth-interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { AuthenticationModule } from './features/authentication/authentication.module';
import { UserProfileModule } from './features/user-profile/user-profile.module';
import { EventsModule } from './features/events/events.module';
import { DesignSystemModule } from './design-system/DesignSystem.module';
import { AnalyticsModule } from './libraries/core/analytics/analytics.module';
import { AppConstants } from './libraries/core/constants/constants.service';
import { IconsModule } from './topcars-ui/icons/icons.module';
import { IconRegistryService } from './topcars-ui/icons/icon-registry.service';
import { Observable } from 'rxjs';
import { ModuleTranslateLoaderService } from './libraries/core/translations/module-translate-loader.service';
import {
  TRANSLATION_PLUGIN,
  TranslationConfig,
} from './libraries/core/translations/translation-config';
import { featureFlagsProvider } from './libraries/core/feature-flags/feature-flag.provider';

export function preloadIcons(
  iconRegistry: IconRegistryService,
): () => Observable<void[]> {
  return () =>
    iconRegistry.preloadIcons([
      {
        name: 'close',
        content: /*html*/ `<svg viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 17.4124L17 1.41235M1 1.41235L17 17.4124" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
      },
    ]);
}

@NgModule({
  declarations: [AppComponent, MainLayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LibrariesModule,
    DesignSystemModule,
    AuthenticationModule,
    UserProfileModule,
    EventsModule,
    HttpClientModule,
    ReactiveFormsModule,
    IconsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient, resources: TranslationConfig[]) =>
          new ModuleTranslateLoaderService(http, resources),
        deps: [HttpClient, TRANSLATION_PLUGIN],
      },
    }),
    AnalyticsModule.forRoot({
      gtagId: AppConstants.GTAG_ID,
    }),
    DialogModule,
    DynamicDialogModule,
  ],
  providers: [
    TextService,
    DialogService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: preloadIcons,
      deps: [IconRegistryService],
      multi: true,
    },
    featureFlagsProvider(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
