import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { COUNTRIES_SELECTOR } from './constants';

@Component({
  selector: 'app-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss'],
})
export class CountrySelectorComponent implements OnChanges {
  isMobile = true;
  params: any;
  countriesData: any;
  searchQuery = '';
  placeholder = '';
  countrySelect!: COUNTRIES_SELECTOR;
  @Input() data!: any;
  @Output() countrySelected = new EventEmitter<COUNTRIES_SELECTOR>();

  constructor(private screenSizeService: ScreenSizeService) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.data = changes['data'].currentValue;
      this.params = this.data?.countries;
      this.placeholder = this.data?.placeholder;
      this.params = this.params?.map((country: any) => ({
        ...country,
        flagUrl: `https://flagcdn.com/${country.code.toLowerCase()}.svg`,
      }));
    }
  }

  get filteredParams() {
    return this.params
      ? this.params.filter((country: any) =>
          country.name.toLowerCase().includes(this.searchQuery.toLowerCase()),
        )
      : [];
  }

  onCountryClick(country: COUNTRIES_SELECTOR) {
    this.countrySelect = country;
    this.countrySelected.emit(country);
    setTimeout(() => {
      this.searchQuery = '';
    }, 1000);
  }

  selectFirstOption(): void {
    if (this.filteredParams.length > 0) {
      const firstCountry = this.filteredParams[0];
      this.onCountryClick(firstCountry);
    }
  }
}
