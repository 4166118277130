import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { TextService } from '../services/text.service';

@Component({
  selector: 'app-tctext',
  templateUrl: './tctext.component.html',
  styleUrls: ['./tctext.component.scss'],
})
export class TCTextComponent implements OnInit {
  @Input() key!: string;
  @Input() feature!: string;
  safeTextValue: SafeHtml = '';
  @Input() isLink = false;
  @Input() linkUrl?: string;

  constructor(
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private textService: TextService,
  ) {}

  ngOnInit(): void {
    this.loadAndTranslateText();
    this.translate.onLangChange.subscribe(() => {
      this.loadAndTranslateText();
    });
  }

  private loadAndTranslateText() {
    this.textService.loadTranslations(this.feature).subscribe(() => {
      this.translate.get(this.key)?.subscribe((rawText: string) => {
        this.safeTextValue = this.sanitizer.bypassSecurityTrustHtml(rawText);
      });
    });
  }
}
