import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from './libraries/core/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'top-cars-web';

  constructor(
    private analyticsService: AnalyticsService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.analyticsService.init();
    this.translateService.use('en');
  }
}
