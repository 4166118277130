export const TRANSLATIONS = {
  authentication: {
    login: {
      signEmail: 'authentication.login.sign_email',
      continue: 'authentication.login.continue',
      signEmailSecond: 'authentication.login.sign_email_second',
      signFooter: 'authentication.login.sign_footer',
      signPlaceholderInput: 'authentication.login.sign_placeholder_input',
      signEmailButton: 'authentication.login.sign_email_button',
      options: 'authentication.login.options',
      signPhone: 'authentication.login.sign_phone',
      signApple: 'authentication.login.sign_apple',
      signGoogle: 'authentication.login.sign_google',
      errorMessage: 'authentication.login.error_message',
      errorPost: 'authentication.login.error_post',
      responseError: 'authentication.login.response_error',
      errorMessagePassword: 'authentication.login.error_message_password',
      country: 'authentication.login.country',
    },
    verification: {
      title: 'authentication.verification.title',
      message: 'authentication.verification.message',
      messagePhone: 'authentication.verification.message_phone',
      messageResend: 'authentication.verification.message_resend',
      resend: 'authentication.verification.resend',
      continue: 'authentication.verification.continue',
      requestCode: 'authentication.verification.request_code',
      maximumAttempts: 'authentication.verification.maximum_attempts',
      errorPost: 'authentication.verification.error_post',
      userNotFound: 'authentication.verification.user_not_found',
      invalidCode: 'authentication.verification.invalid_code',
      codeExpired: 'authentication.verification.code_expired',
    },
    phone: {
      title: 'authentication.phone.title',
      subtitle: 'authentication.phone.subtitle',
      placeholder: 'authentication.phone.placeholder',
      errorMessage: 'authentication.phone.error_message',
      continue: 'authentication.phone.continue',
      options: 'authentication.phone.options',
      signEmail: 'authentication.phone.sign_email',
      signApple: 'authentication.phone.sign_apple',
      signGoogle: 'authentication.phone.sign_google',
      signFooter: 'authentication.phone.sign_footer',
      errorPost: 'authentication.phone.error_post',
      responseError: 'authentication.phone.response_error',
      countrySelectorPlaceholder:
        'authentication.phone.country_selector_placeholder',
      countrySelector: 'authentication.phone.country_selector',
      countrySearch: 'authentication.phone.country_search',
      noResults: 'authentication.phone.no_results',
    },
  },
  userProfile: {
    addEmail: {
      title: 'userProfile.add_email.title',
      subtitle: 'userProfile.add_email.subtitle',
      addPlaceH: 'userProfile.add_email.add_place_h',
      errorMessage: 'userProfile.add_email.error_message',
      messageVal: 'userProfile.add_email.message_val',
      continue: 'userProfile.add_email.continue',
      errorPost: 'userProfile.add_email.error_post',
      responseError: 'userProfile.add_email.response_error',
      userConflict: 'userProfile.add_email.user_conflict',
    },
    username: {
      title: 'userProfile.username.title',
      subtitle: 'userProfile.username.subtitle',
      textBase: 'userProfile.username.text_base',
      textBaseUser: 'userProfile.username.text_base_user',
      continue: 'userProfile.username.continue',
      userPlaceHinput: 'userProfile.username.user_place_hinput',
      errorMessage: 'userProfile.username.error_message',
      errorPost: 'userProfile.username.error_post',
      responseError: 'userProfile.username.response_error',
      userConflict: 'userProfile.username.user_conflict',
    },
    profile: {
      title: 'userProfile.profile.title',
      subtitle: 'userProfile.profile.subtitle',
      continue: 'userProfile.profile.continue',
      userPlaceHinput: 'userProfile.profile.user_place_hinput',
      userPlaceHbirthdate: 'userProfile.profile.user_place_hbirthdate',
      takePhoto: 'userProfile.profile.take_photo',
      errorMessage: 'userProfile.profile.error_message',
      errorMessageB: 'userProfile.profile.error_message_b',
      errorPost: 'userProfile.profile.error_post',
      responseError: 'userProfile.profile.response_error',
      responseErrorAvatar: 'userProfile.profile.response_error_avatar',
      userConflict: 'userProfile.profile.user_conflict',
      import: 'userProfile.profile.import',
      remove: 'userProfile.profile.remove',
      titlePermissionsCamera: 'userProfile.profile.title_permissions_camera',
      titlePermissionsLocation:
        'userProfile.profile.title_permissions_location',
      titlePermissionsNotification:
        'userProfile.profile.title_permissions_notification',
      subtitlePermissionsCamera:
        'userProfile.profile.subtitle_permissions_camera',
      subtitlePermissionsLocation:
        'userProfile.profile.subtitle_permissions_location',
      subtitlePermissionsNotification:
        'userProfile.profile.subtitle_permissions_notification',
      adds: 'userProfile.profile.adds',
      allow: 'userProfile.profile.allow',
    },
  },
} as const;
