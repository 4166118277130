import { TypesButton } from '@/app/design-system/atoms/button/constants';
import { AuthService } from '@/app/libraries/authentication/services/auth-service.service';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';
import { Component } from '@angular/core';
import {
  PATH_CITIES,
  PATH_CITIES_SUBSCRIBE,
} from '@/app/libraries/events/constants';
import { HttpMethodsService } from '@/app/libraries/core/http-methods/services/http-methods-service.service';
import { ToolbarNavigationService } from '@/app/libraries/core/navigation/services/toolbar-navigation.service';
import { SignInScreenName } from '@/app/features/authentication/login/login.config';
import { QueryParam } from '@/app/libraries/main-layout/constants/screen-configuration';
import { CityDetailScreenTitleConfig } from '@/app/features/events/city-detail/city-detail.config';
import { ErrorModalService } from '@/app/libraries/core/modals/services/error-modal.service';

@Component({
  selector: 'app-tc-background',
  templateUrl: './tc-background.component.html',
  styleUrls: ['./tc-background.component.scss'],
})
export class TcBackgroundComponent {
  data: any;
  TypesButton = TypesButton;
  isMobile = false;
  isLoggedIn = false;
  isLoading = false;
  CityDetailScreenTitleConfig = CityDetailScreenTitleConfig;

  constructor(
    private authService: AuthService,
    private screenSizeService: ScreenSizeService,
    private errorModalService: ErrorModalService,
    private httpMethodsService: HttpMethodsService,
    private toolbarNavigationService: ToolbarNavigationService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.authService.isLoggedIn$.subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
    });
  }

  httpServicesPost() {
    if (this.isLoggedIn) {
      this.isLoading = true;
      const params = {
        interested: true,
      };
      const path = `${PATH_CITIES}${this.data?.city?.key}${PATH_CITIES_SUBSCRIBE}`;
      this.httpMethodsService.postData(path, params).subscribe({
        next: (response: any) => {
          if (response.status === 200 || response.status === 201) {
            window.location.reload();
          }
        },
        error: async () => {
          await this.errorModalService.showError({
            feature: this.CityDetailScreenTitleConfig.feature,
            key: this.CityDetailScreenTitleConfig.responseError,
          });
          this.isLoading = false;
        },
      });
    } else {
      this.signIn();
    }
  }

  signIn() {
    this.toolbarNavigationService.navigateForwardModal(SignInScreenName, {
      modal: window.btoa(QueryParam.flow),
    });
  }
}
