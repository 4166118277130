<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '100%' }"
  [draggable]="false"
  [resizable]="false"
  [showHeader]="false"
  [styleClass]="modalClosing ? 'user-lang-modal closing' : 'user-lang-modal'"
>
  <div id="modal-content-lang" class="modal-content" pb-24>
    <div class="close">
      <app-tc-icon
        [iconId]="'system_close_modal'"
        (click)="closeModal()"
      ></app-tc-icon>
    </div>
    <app-language-dropdown [priority]="true"></app-language-dropdown>
  </div>
</p-dialog>
