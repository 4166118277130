import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';
import { APP_ROUTES } from '@/app/app-routing.module';
import { AuthService } from '@/app/libraries/authentication/services/auth-service.service';
import { TypesButton } from '../../atoms/button/constants';
import { userData } from '@/app/features/authentication/constants';
import { NavigationService } from '@/app/libraries/main-layout/services/navigation.service';
import { LinkRedirects } from '../footer/constants';
import { ToolbarNavigationService } from '@/app/libraries/core/navigation/services/toolbar-navigation.service';
import { SignInScreenName } from '@/app/features/authentication/login/login.config';
import { QueryParam } from '@/app/libraries/main-layout/constants/screen-configuration';
import { EN } from '@/app/libraries/core/navigation/toolbar.interface';
import { UserConfigComponent } from '../../modals/user-config/user-config.component';
import { UserLanguageComponent } from '../../modals/user-language/user-language.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('fadeSlide', [
      transition(':enter', [
        style({ top: '0', opacity: 0 }),
        animate('0.3s ease-out', style({ top: '4rem', opacity: 1 })),
      ]),
      transition(':leave', [
        animate('0.3s ease-in', style({ top: '0', opacity: 0 })),
      ]),
    ]),
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  isDropdownOpen = false;
  isDropUser = false;
  language = EN;
  isMobileMenuOpen = false;
  isMobile = false;
  isLoggedIn = false;
  dataUser: userData | null = null;
  TypesButton = TypesButton;
  LinkRedirects!: LinkRedirects;
  linkPage!: string;
  @ViewChild(UserConfigComponent) userConfig!: UserConfigComponent;
  @ViewChild(UserLanguageComponent) userLang!: UserLanguageComponent;

  constructor(
    private authService: AuthService,
    public navigationService: NavigationService,
    private screenSizeService: ScreenSizeService,
    private toolbarNavigationService: ToolbarNavigationService,
    private elementRef: ElementRef,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.authService.isLoggedIn$.subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
    });
    this.authService.dataUser$.subscribe((data) => {
      this.dataUser = data;
    });
    this.linkPage = LinkRedirects.TopCars;
    this.authService.languageChanged$.subscribe((value) => {
      this.language = value ? value : EN;
    });
  }

  ngOnInit() {
    document.addEventListener('click', this.handleDocumentClick.bind(this));
    document.addEventListener('keydown', this.handleKeyDown.bind(this));
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.handleDocumentClick.bind(this));
    document.removeEventListener('keydown', this.handleKeyDown.bind(this));
  }

  toggleDropdown() {
    if (this.isMobile) {
      this.userLang.openModal();
    } else {
      this.isDropdownOpen = !this.isDropdownOpen;
      if (this.isDropUser) {
        this.isDropUser = !this.isDropUser;
      }
    }
  }
  toggleDropUser() {
    if (this.isMobile) {
      this.userConfig.openModal();
    } else {
      this.isDropUser = !this.isDropUser;
      if (this.isDropdownOpen) {
        this.isDropdownOpen = !this.isDropdownOpen;
      }
    }
  }

  goHome() {
    this.toolbarNavigationService.navigateForward(
      [APP_ROUTES.EVENTS],
      {},
      null,
    );
  }

  signIn() {
    this.toolbarNavigationService.navigateForwardModal(SignInScreenName, {
      modal: window.btoa(QueryParam.flow),
    });
  }

  private handleDocumentClick(event: MouseEvent): void {
    const clickedInsideDropdown = this.elementRef.nativeElement.contains(
      event.target,
    );
    if (!clickedInsideDropdown) {
      this.isDropdownOpen = false;
      this.isDropUser = false;
    }
  }

  private handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.isDropdownOpen = false;
      this.isDropUser = false;
    }
  }
}
