<!-- <app-toolbar
  w-100
  pt-12
  [toolbarConfig]="{
    state: true,
    close: true,
    title: {
      active: true,
      feature: 'g-auth',
      key: 'phone.country',
    },
  }"
></app-toolbar> -->
<div class="content-pages">
  <div w-100 TextStyle class="search-input">
    <app-tc-icon [iconId]="'system_search'" class="icon-search"></app-tc-icon>
    <input
      type="text"
      [placeholder]="placeholder"
      [(ngModel)]="searchQuery"
      (keydown.enter)="selectFirstOption()"
    />
  </div>
  <div class="countries">
    <button
      *ngFor="let country of filteredParams; let i = index"
      (click)="onCountryClick(country)"
      [id]="'country-' + i"
    >
      <img [src]="country.flagUrl" alt="Flag of {{ country.name }}" />
      <p m-0 fz-16 fw-500 tcMilk>{{ country.name }}</p>
      <span m-0 tcConcrete style="padding-right: var(--size-4)">{{
        country.dial_code
      }}</span>
    </button>
    <button *ngIf="filteredParams.length === 0">
      <app-tctext
        tcMilk
        TextStyle
        fz-16
        feature="g-auth"
        key="phone.noResults"
        text-center
      ></app-tctext>
    </button>
  </div>
</div>
