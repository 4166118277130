import { Injectable } from '@angular/core';
import { ValidatorPlugin } from '../validator-plugin.interface';
import { EmailValidatorPlugin } from '@/app/libraries/authentication/validators/email-validator.plugin';
import { PasswordValidatorPlugin } from '@/app/libraries/authentication/validators/password-validator.plugin';
import { PhoneValidatorPlugin } from '@/app/libraries/authentication/validators/phone-validator.plugin';
import { UsernameValidatorPlugin } from '@/app/libraries/user-profile/validators/username-validator.plugin';
import { NameValidatorPlugin } from '@/app/libraries/user-profile/validators/name-validator.plugin';
import { BirthdateValidatorPlugin } from '@/app/libraries/user-profile/validators/birthdate-validator.plugin';
import {
  BIRTHDATE,
  EMAIL,
  NAME,
  PASSWORD,
  PHONE,
  USERNAME,
} from '../constants';

@Injectable({
  providedIn: 'root',
})
export class ValidatorService {
  private validators: { [key: string]: ValidatorPlugin } = {};

  constructor() {
    this.validators[EMAIL] = new EmailValidatorPlugin();
    this.validators[PASSWORD] = new PasswordValidatorPlugin();
    this.validators[USERNAME] = new UsernameValidatorPlugin();
    this.validators[NAME] = new NameValidatorPlugin();
    this.validators[BIRTHDATE] = new BirthdateValidatorPlugin();
    this.validators[PHONE] = new PhoneValidatorPlugin();
  }

  getValidator(key: string): ValidatorPlugin | undefined {
    return this.validators[key];
  }
}
