import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-auth-flow-auth',
  templateUrl: './auth-flow.component.html',
  styleUrls: ['./auth-flow.component.scss'],
})
export class AuthFlowComponent {
  @Input() profile = false;
}
