<ng-container *ngIf="isLoading">
  <app-loading-skeleton
    w-100
    [type]="'card-v2'"
    [count]="1"
  ></app-loading-skeleton>
</ng-container>
<div class="city-back" *ngIf="!isLoading">
  <div class="img-wrapper">
    <img
      [src]="data.city.imageUrl"
      class="img-background"
      alt="city-background"
    />
    <div class="gradient-overlay"></div>
  </div>
  <div class="info-text">
    <p tcConcrete fz-12 fw-500 m-0 [ngClass]="{ latest: !isMobile }">
      Latest events in
    </p>
    <div class="city-name">
      <p tcMilk fz-22 fw-500 m-0 [ngClass]="{ cityName: !isMobile }">
        {{ data.city.name + ", " + data.city.state }}
      </p>
      <app-tc-icon
        [ngClass]="{ 'pointers-none': data?.subscribed }"
        [iconId]="
          !data?.subscribed
            ? 'system_subscribe_moweb'
            : 'system_subscribed_moweb'
        "
        class="icon"
        *ngIf="isMobile"
        (click)="httpServicesPost()"
      ></app-tc-icon>
    </div>
    <p
      tcMilk
      fz-14
      fw-400
      m-0
      pb-16
      *ngIf="!isMobile && data?.city?.description"
      style="width: calc(100% - var(--size-24))"
    >
      {{ data.city.description }}
    </p>
    <div
      style="width: 8rem; padding-bottom: var(--size-16)"
      *ngIf="!isMobile"
      (click)="httpServicesPost()"
    >
      <app-tcbutton
        [type]="TypesButton.tertiaryWhite"
        [round]="true"
        [icon]="!data?.subscribed ? 'system_plus' : 'system_check_subscribe'"
        [disabled]="data?.subscribed"
      >
        <app-tctext
          feature="g-events"
          [key]="!data?.subscribed ? 'events.subscribe' : 'events.subscribed'"
        ></app-tctext>
      </app-tcbutton>
    </div>
  </div>
</div>
