import { Component, Input, OnInit } from '@angular/core';
import { eventTime } from '@/app/libraries/events/sdui/sdui-section.interface';
import { DateService } from '@/app/libraries/core/dates/services/date.service';
import { dateFormat } from '@/app/libraries/core/dates/date.interface';

@Component({
  selector: 'app-tc-upcoming-card',
  templateUrl: './tc-upcoming-card.component.html',
  styleUrls: ['./tc-upcoming-card.component.scss'],
})
export class TcUpcomingCardComponent implements OnInit {
  @Input() imageUrl!: string;
  @Input() eventOrganizer!: string;
  @Input() eventTitle!: string;
  @Input() eventLocation!: string;
  @Input() eventTime!: eventTime;
  initialDateValues!: dateFormat;
  finalDateValues!: dateFormat;

  constructor(private dateService: DateService) {}

  ngOnInit(): void {
    this.initialDateValues = this.dateService.convertToLocalDate(
      this.eventTime.initial_date,
    );
    this.finalDateValues = this.dateService.convertToLocalDate(
      this.eventTime.final_date,
    );
  }
}
