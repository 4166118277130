import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppConstants {
  static readonly API_URL = import.meta.env.NG_APP_API_URL;
  static readonly GTAG_ID = import.meta.env.NG_APP_GTAG_ID;
  static readonly PUBLIC_KEY = import.meta.env.NG_APP_PUBLIC_KEY;
  static readonly PUBLIC_URLS = [
    'api/v1/events/sdui/',
    'api/v1/cities/',
    'api/v1/events/',
    // 'api/v1/featureflags/',
  ];
}
