import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { AuthService } from '../services/auth-service.service';
import { Router } from '@angular/router';
import { AUTH_ROUTES } from '@/app/features/authentication/authentication-routing.module';
import { AppConstants } from '@/app/libraries/core/constants/constants.service';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject = new BehaviorSubject<string | null>(null);
  isMobile = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private screenSizeService: ScreenSizeService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  intercept<T>(
    req: HttpRequest<T>,
    next: HttpHandler,
  ): Observable<HttpEvent<T>> {
    const authToken = this.authService.getAuthToken();
    const language = this.authService.getLanguage();
    const clonedRequest = this.addAuthHeader(req, authToken, language);

    return next.handle(clonedRequest).pipe();
  }

  private addAuthHeader<T>(
    req: HttpRequest<T>,
    token: string | null,
    language: string | null,
  ): HttpRequest<T> {
    const isPublicUrl = AppConstants.PUBLIC_URLS.some((url) =>
      req.url.includes(`/${url}`),
    );

    const defaultHeaders = {
      'Topcars-Platform': this.isMobile ? 'moweb' : 'web',
    };

    if (isPublicUrl && !token) {
      return req.clone({
        setHeaders: {
          'X-API-KEY': AppConstants.PUBLIC_KEY,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Topcars-Platform': this.isMobile ? 'moweb' : 'web',
        },
      });
    }

    if (token) {
      return req.clone({
        setHeaders: {
          Authorization: `Token ${token}`,
          'Accept-Language': language || 'en',
          ...defaultHeaders,
        },
      });
    }

    return req.clone({
      setHeaders: {
        ...defaultHeaders,
      },
    });
  }

  private handle401Error<T>(
    req: HttpRequest<T>,
    next: HttpHandler,
  ): Observable<HttpEvent<T>> {
    const language = this.authService.getLanguage();
    if (this.refreshTokenInProgress) {
      return this.refreshTokenSubject.pipe(
        filter((token): token is string => token !== null),
        take(1),
        switchMap((token) =>
          next.handle(this.addAuthHeader(req, token, language)),
        ),
      );
    } else {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((newToken: string) => {
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(newToken);
          return next.handle(this.addAuthHeader(req, newToken, language));
        }),
        catchError((err) => {
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.error(err);

          this.router.navigate([AUTH_ROUTES.LOGIN]);
          return throwError(() => err);
        }),
      );
    }
  }
}
