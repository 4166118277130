<div class="event-container">
  <div class="event-layout">
    <app-loading-skeleton
      w-100
      [type]="'card-v2'"
      [count]="1"
    ></app-loading-skeleton>
    <app-loading-skeleton
      *ngIf="!isMobile"
      [type]="'label'"
      [count]="1"
      style="width: 20%; height: 1rem; padding: 0 0 var(--size-16) 0"
    ></app-loading-skeleton>
    <div d-flex flex-column [ngClass]="{ 'grid-desktop': !isMobile }">
      <app-loading-skeleton
        w-100
        pb-28
        [type]="'card'"
        [count]="isMobile ? MOBILE_SKELETON_COUNT : DESKTOP_SKELETON_COUNT"
        style="padding: 0 0 var(--size-24) 0"
      ></app-loading-skeleton>
      <app-loading-skeleton
        w-100
        pb-28
        [type]="'card'"
        [count]="isMobile ? MOBILE_SKELETON_COUNT : DESKTOP_SKELETON_COUNT"
        style="padding: 0 0 var(--size-24) 0"
      ></app-loading-skeleton>
      <app-loading-skeleton
        w-100
        pb-28
        [type]="'card'"
        [count]="1"
        style="padding: 0 0 var(--size-24) 0"
      ></app-loading-skeleton>
      <app-loading-skeleton
        *ngIf="!isMobile"
        w-100
        pb-28
        [type]="'card'"
        [count]="1"
        style="padding: 0 0 var(--size-24) 0"
      ></app-loading-skeleton>
    </div>
  </div>
</div>
