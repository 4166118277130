export interface TranslationConfig {
  prefix?: string;
  module: string;
  suffix?: string;
}

// Provide an injection token for the plugins
import { InjectionToken } from '@angular/core';

export const TRANSLATION_PLUGIN = new InjectionToken<TranslationConfig[]>(
  'TRANSLATION_PLUGIN',
);
