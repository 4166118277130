import { ICON_CATALOG } from '@/app/libraries/core/icon/icon-catalog';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tc-card-explore-events',
  templateUrl: './tc-card-explore-events.component.html',
  styleUrls: ['./tc-card-explore-events.component.scss'],
})
export class TcCardExploreEventsComponent {
  @Input() participants!: string;
  @Input() dateTime!: string;
  @Input() eventTitle!: string;
  @Input() eventLocation!: string;

  system_explore_events = '';

  constructor() {
    this.system_explore_events = ICON_CATALOG['system_explore_events'] || '';
  }
}
